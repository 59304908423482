.navbarico {
  width: 30px;
  height: 30px;
  position: fixed;
  top: 5px;
  left: 0;
}

.nav {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  justify-content: end;
  z-index: 500;
}

.btn_wrap {
  display: flex;
  align-items: center;

}

.btn_part {
  color: var(--my-bg-colorlight);
  border: none;
  background: linear-gradient(0deg, var(--my-bg-colordark) 0%, var(--my-bg-colordark) 100%);

  font-size: 14px;
  margin: 0;
  height: 99%;
  width: 4rem;
}

._left {
  @extend .btn_part;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 25px;
}

._right {
  @extend .btn_part;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 0.5rem;
}

.btn-part_active {
  box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, .5), -4px -4px 6px 0 rgba(116, 125, 136, .5), inset -4px -4px 6px 0 rgba(255, 255, 255, .2), inset 4px 4px 6px 0 rgba(0, 0, 0, .4);
  font-size: 1.25rem;
}

.btn-part_unactive {
  box-shadow: none;
  font-size: 14px;
  background-color: transparent;
}

.btnlast {
  border-top-right-radius: 25px;
  height: 100%;
}

.btnmedium {
  height: 99%;
  border-radius: 0;
  width: 7rem;
  margin: 0;
  font-size: small;
}



.btn_myschedule {
  display: flex;
  border-radius: 0;
  border-bottom-left-radius: 25px;
  border: 1px solid var(--my-border-color);
  color: var(--my-border-color);
  min-width: 5rem;
  height: 100%;
  font-style: italic;

}





.string_menu_empty {
  z-index: 1;
}


@media screen and (min-width: 600px) {
  .nav {
    font-size: large;
  }
}

@media screen and (max-width: 600px) {
  .nav {
    font-size: small;
    padding-right: 0rem;
  }
}