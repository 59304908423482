.weeknumbers_div {
    font-size: 1rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
    border: 1px solid #CED4DA;
    border-radius: 15px;
    padding-left: 0.5rem;

    background-color: #E9ECEF;
    margin-bottom: 0.3rem;
    align-items: center;

}

.roundcheck_div {
    display: flex;
    justify-content: flex-end;

}

.createNewBtn {
    border-bottom-left-radius: 25px;
    border-top-left-radius: 0px;
    border-top-right-radius: 25px;
    height: 100%;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border: none;

    font-family: 'Lato', sans-serif;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;

    outline: none;
    border: none;
    color: #fff;
    background: linear-gradient(0deg, var(--my-bg-colordark) 0%, var(--my-bg-colordark) 100%);
    text-decoration: none;
    text-wrap: nowrap;
    height: fit-content;

}

.createNewBtn:hover {
    box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, .5),
        -4px -4px 6px 0 rgba(116, 125, 136, .5),
        inset -4px -4px 6px 0 rgba(255, 255, 255, .2),
        inset 4px 4px 6px 0 rgba(0, 0, 0, .4);
}

.badgeOn {
    width: 100%;
    font-size: 0.75rem;
    font-weight: 700;
    padding: 0.1rem;
    border-radius: 10px;
    margin-top: 0.3rem;

}

.badgeOff {
    width: 100%;
    font-size: 0.75rem;
    font-weight: 700;
    padding: 0.1rem;
    border-radius: 10px;
    margin-top: 0.3rem;
}

.badgeWeekOn {
    width: 100%;
    font-size: 0.75rem;
    font-weight: 700;
    padding: 0.1rem;
    border-radius: 10px;
    margin-left: 0.3rem;
    height: 1.5rem;
    width: 1.5rem;

}

.badgeWeekOff {
    width: 100%;
    font-size: 0.75rem;
    font-weight: 700;
    padding: 0.1rem;
    border-radius: 10px;
    margin-left: 0.3rem;
    height: 1.5rem;
    width: 1.5rem;
}

.currentWeek {
    margin-left: 1rem;
    width: 4rem;
    height: 1.8rem;
    padding: 0;
    padding-left: 0.5rem;
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
}

.titleForm {
    /* "d-flex justify-content-between" style={{ width: "27rem" }} */
    display: flex;
    justify-content: space-between;
    max-width: 27rem;
}

.weekDaybadgeOn {
    font-size: 0.75rem;
    font-weight: 700;
    padding: 0.1rem;
    border-radius: 10px;
    margin-top: 0.3rem;
    margin-left: 0.3rem;
    width: 3rem;
    height: 1.5rem;
    align-items: start;
}

.weekDaybadgeOff {
    width: 100%;
    font-size: 0.75rem;
    font-weight: 700;
    padding: 0.1rem;
    border-radius: 10px;
    margin-top: 0.3rem;
    margin-left: 0.3rem;
    width: 3rem;
    height: 1.5rem;
}

.weekStartRow {
    display: flex;
    justify-content: space-between;
    width: 75%;
}

@media screen and (max-width: 600px) {
    .weekDaybadgeOn {
        width: 2rem;
        height: 1.5rem;
    }

    .weekDaybadgeOff {
        width: 2rem;
        height: 1.5rem;
    }

    .weekStartRow {
        width: 65%;
        justify-content: space-around;
    }

}