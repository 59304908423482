.wraptitleDay {
    border: 1px solid #d2d2d2;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
    margin-bottom: 4px;
    padding-bottom: 10px;
    position: relative;
}

.dayCHeadMode1 {
    padding: 8px 16px;
    border-radius: 10px 10px 0 0 !important;
    border-color: var(--neutral-color);
}

.dayCHeadMode2 {
    display: flex;
    justify-content: space-between;
    font-size: "1wv";
    border-bottom: none;
}

.width15 {
    width: 15rem;
}

.lessBoxC {
    width: 100%;
    border-bottom: 0.3px solid #F5F5F5;
    position: relative;
    background: white;
}

.lessBoxCPlaceholder {
    opacity: 0.2;
    height: 1.5rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    font-size: 15px;
    padding-left: 5px;
}

.lessBoxCText {

    height: 1.5rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-left: 5px;
    max-width: 12rem;
    width: 12rem;
}

.lessBoxCempty {
    border: 0.3px solid #F5F5F5;
    color: gray;
    text-align: center;
    width: 20rem;
    background-color: #F7F7F7;
    height: 1.5rem;
    padding-bottom: 1px;
    margin-bottom: 1px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 100%;
}



.lessNameWeek {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.lessNameDay {
    width: 16rem;
    display: flex;
    justify-content: stretch;
    align-items: stretch;

}

.lessName {
    width: 100%;
    border: 1px solid grey;
}

.intervalWeek {
    border: 1px solid var(--my-border-color);

}

.wrap-intervalBox {
    display: flex;
}

.intervalInput,
.intervalInputWeek {
    text-align: center;
    height: 100%;
    border-radius: 0%;
    box-sizing: border-box;
    border: 1px solid var(--my-border-color);
    border: none;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.intervalInputWeek {
    box-shadow: none;
}

.btn_picker {
    z-index: 1;
    position: absolute;
    color: var(--my-border-color);
    border: 1px solid #CED4DA;
    width: 2.1rem;
    height: 2.4rem;
    height: 100%;
    background: white;
    border: 1px solid var(--my-border-color);
    border-left: none;
    border: none;
    font-size: 14px;
}

.intervalDiv {
    height: 100%;
    background-color: transparent;
    display: table;
    border-collapse: collapse;
    border-color: var(--my-border-color);
    border-bottom: 1px solid var(--my-border-color);
    border-top: 1px solid var(--my-border-color);
}

.interval_btnLeft {
    width: 1.5rem;
    border: 1px solid var(--my-border-color);
    font-size: 1rem;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 50%;
    border-top-left-radius: 50%;
    border: none;
    font-weight: 700;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;

}

.interval_btnRight {
    border-radius: 0% 50% 50% 0%;
    width: 1.5rem;
    border: none;
    font-size: 1rem;
    font-weight: 700;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;

}

.lessNameWeek1 {
    display: flex;
    height: 1.5rem;
    width: 100%;
    padding-bottom: 1px;
    margin-bottom: 1px;
}

.lessNameDay1 {
    width: 16rem;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    flex-direction: column;
    border-top: 1px solid var(--neutral-color);
}

.bodyBox {
    border-top: 1px solid var(--neutral-color);
}

.lessName1 {
    width: 100%;
    border: 1px solid grey;
    justify-content: flex-start;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-wrap: nowrap;
    display: flex;
    min-width: 5rem;
    height: 1.5rem;
}



.labelLess {
    min-width: 10rem;
    max-width: 15rem;
    justify-content: end;
}

.btn {
    border-radius: 50%;
}


.btns-title {
    display: flex;
    border-radius: 50%;
}

.btnView {
    font-size: 14px;
    height: 2.4rem;
    height: 100%;
    width: 2.4rem;
    border-radius: 25%;
    background-color: white;
    border: 1px solid var(--my-border-color);
    color: black;
    border: none;

    font-weight: 700;

    &:hover {
        background-color: var(--neutral-color);
        color: black;
    }
}

.btnShort {
    height: 2.4rem;
    height: 100%;
    width: 2.4rem;
    text-align: bottom;
    border-radius: 0;
    background-color: var(--my-bg-colordark);
    border: 1px solid var(--my-border-color);
    color: var(--my-bg-colorlight);
    font-weight: 700;
    border-top-right-radius: 25px;
}



.labelmodalLess {
    min-width: 20rem;
    max-width: 25rem;
    justify-content: end;
    font-size: 2rem;

}