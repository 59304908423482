.schedule {
    display: flex;
    justify-content: space-around;
    display: flex;
    width: 100%;
    justify-content: center;
    overflow-x: auto;
    border: none;
    transition: all 0.2s ease-in-out;
}

.planWrap {
    display: flex;
    justify-content: center;
    position: relative;
    transition: all 0.2s ease-in-out;

}

.cardWrap {
    border: none;
    margin-left: 0.2rem;


}

.cardHeader,
.cornerBtn {
    background-color: rgb(243, 241, 241);
    display: flex;
    justify-content: space-between;
    border: 1px solid rgb(202, 202, 202);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    border-radius: 5px;
}

.cardBody {
    padding: 0;
    border: none;
}

.oneDay {
    width: 6.7rem;
    margin: 2px;
}

.oneDayC {
    border: none;
    padding: 5px 2px;
}

.modaledit {
    height: 11rem;
}

.classWrap {
    width: 100%;
}

.oneLessbox,
.addNewLbox {
    font-size: 1rem;
    border-radius: 0.375rem;
    width: 100%;
    height: 5.9rem;
    margin: auto;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
    margin-top: .25rem;
}



.oneLesscard {
    font-size: 1rem;
    width: 7rem;
    border-radius: 0.375rem;
    margin-top: .25rem;
    height: 2.1rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-top: .25rem;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;


}

.oneLessbox:hover,
.oneLesscard:hover {
    cursor: move;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}


.colorBg {
    padding-left: 10px;
    background-color: rgb(243, 241, 241);
    padding-left: 20px;
    position: relative;

    span {
        border-radius: 5px 0 50% 0;
        background-color: #d2d2d2;
        position: absolute;
        left: 0;
        width: 18px;
        text-align: center;
    }
}

.subjWrap {
    display: flex;
    justify-content: flex-end;

}

.subjWrapShow {
    display: flex;
    justify-content: flex-end;
    width: -webkit-fill-available;
}

.subjBtn {
    position: absolute;
    right: 0;
    top: -50px;
}

.addNewBtn {
    text-align: center;
    line-height: 2;
    background-color: rgb(243, 241, 241);
}

.marginOn {
    margin-right: 250px;
    overflow-x: auto;
    width: 100%;
}

.marginOff {
    width: 100%;
}



.nowrap {
    flex-wrap: nowrap;

}

.wrap {
    flex-wrap: wrap;
}


.weeknumbers_div {
    font-size: 1rem;
    height: 2.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 100%; */
    border: 1px solid #CED4DA;
    border-radius: 15px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    background-color: green;
    margin: auto;
}

.roundcheck_div {
    display: flex;
    justify-content: flex-end;

}

.round {
    accent-color: green;
    width: 15px;
    height: 15px;

}

.round:checked {
    background-color: green;
    border-color: green;
}

@media screen and (max-height:500px) {
    .schedule {
        justify-content: start;
    }
}

@media screen and (max-width:600px) {
    .schedule {
        justify-content: start;
    }

    .oneDay {
        width: 3.1rem;

    }

    .oneLessbox {
        margin: 0;
        width: 2.7rem;
        height: 2.7rem;
        font-size: 2vw;
    }

    .cardHeader,
    .oneLesscard {
        padding: 5px;
        width: 2.7rem;
        font-size: 10px;
        min-width: 2.7rem;
        height: 1.5rem;
    }

    .cornerBtn {
        padding: 5px;
        height: max-content;
        font-size: 10px;
        min-width: 2.7rem;
    }
}