@import 'animate.css';
.interval-enter {
  animation: fadeIn;
  animation-duration: 500ms;
}
.interval-enter-done {   
   animation: fadeIn;
  animation-duration:500ms;
}
.interval-exit {
  animation: fadeOut;
  animation-duration: 500ms;
}
.interval-exit-done {   
   animation: fadeOut;
  animation-duration: 500ms;
} 

.timeRight-enter {
    animation: fadeInRight;
    animation-duration: 500ms;
}.timeRight-enter-done {   
     animation: fadeInRight;
    animation-duration:500ms;
}.timeRight-exit {
    animation: fadeOutLeft;
    animation-duration: 500ms;
}.timeRight-exit-done {   
     animation: fadeOutLeft;
    animation-duration: 500ms;
} 
  
.timeLeft-enter {
    animation: fadeInLeft;
    animation-duration: 500ms;
}.timeLeft-enter-done {   
     animation: fadeInLeft;
    animation-duration:500ms;
}.timeLeft-exit {
    animation: fadeOutRight;
    animation-duration: 500ms;
}.timeLeft-exit-done {   
     animation: fadeOutRight;
    animation-duration: 500ms;
} 
 


.page-appear {
  animation: fadeIn;
  animation-duration: 1000ms;
}.page-appear-done {   
  animation: fadeIn;
  animation-duration: 1000ms;
} 