.dot {
   height: 15px;
   width: 15px;
   background-color: rgb(43, 255, 0);
   border: 1px solid gray;
   background-color: var(--my-bg-colordark);
   ;
   border: 1px solid var(--my-border-color);
   border-radius: 50%;
   position: absolute;
   right: 10px;
   top: 5px;
}