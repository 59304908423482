:root {
  --my-bg-colordark: #198754;
  /* --my-bg-colordark: #1f3e30; */
  --my-border-color: #393B3D;
  --my-bg-colorlight: #ffffff;
  --neutral-color: #ebebeb;

  --text-color: #707780;
  --background-wrap: #f8f8f8;
  --border-color: #2749a5;
  --border-color: #1a2c6d;
  --back-card: #eeeeee;
  --fill-color: #e0e6ea;
  --light-color: #8fd4c1;
  --soft-color: #0fb084;
  --highlighter-color: #0daffd;

}


.App {
  text-align: center;

}

.standart-flex {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.mainImg {
  background-image: url(../img/mainBg.jpg);
  background-image: url(../img/desk.jpg);

  background-size: 100%;
  min-height: 100vh;
  background-attachment: scroll;
  filter: blur(3px);
  z-index: -4;
  position: fixed;
  left: 0;
  right: 0;
  border-top-right-radius: 35px;
}

.noBlur {
  filter: none;
}

.marginUp5 {
  margin-top: 5rem;
}

.marginUp3 {
  margin-top: 3rem;
}

.sideb-enter {
  animation: fadeInDown;
  animation-duration: 500ms;
}

.sideb-enter-done {
  animation: fadeInDown;
  animation-duration: 500ms;
}

.sideb-exit {
  animation: fadeOutUp;
  animation-duration: 500ms;
}

.sideb-exit-done {
  animation: fadeOutUp;
  animation-duration: 5000ms;
}

.sidebar-enter {
  animation: fadeInRightBig;
  animation-duration: 500ms;
}

.sidebar-enter-done {
  animation: fadeInRightBig;
  animation-duration: 500ms;
}

.sidebar-exit {
  animation: fadeOutRightBig;
  animation-duration: 500ms;
}

.sidebar-exit-done {
  animation: fadeOutRightBig;
  animation-duration: 5000ms;
}

.tsp-wrap {
  height: 100%;
  display: flex;
  padding-right: 2.8rem;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.div_tsp_input {
  position: relative;
  width: 2.4rem;
  display: flex;
  justify-content: flex-end;
  border: none;
  position: absolute;
  right: 3px;
}

.form_border_r {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn_picker {
  z-index: 1;
  position: absolute;
  color: white;
  border: 1px solid #CED4DA;
  width: 2.1rem;
  height: 2.4rem;
  height: 100%;
  background: transparent;
  border-radius: 5px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border: none;
}

.tsp_input {
  width: 2rem;
  opacity: 0;
  z-index: 500;
  font-size: large;
  padding-right: 5px;
  border: none;

}

.string_menuWrap {
  z-index: 300;
  position: sticky;
  top: 5rem;
}

.string_menu {
  width: 100%;
  min-height: 2.4rem;
  margin-top: 4px;
  background-color: var(--my-bg-colorlight);
  border: none;
  /* border-radius: 5px; */
  border-bottom-left-radius: 25px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 5px;
  color: white;
  color: var(--my-border-color);
  font-size: 1.3vw;
  display: flex;

  flex-wrap: wrap;
  justify-content: space-between;
  box-shadow: inset 0 0 0 3000px rgba(150, 150, 150, 0.192);
  position: fixed;
  position: sticky;
  margin-top: 0rem;
  top: 2.7rem;

}



.string_submenu {
  width: 100%;
  min-height: fit-content;
  border: none;
  border-radius: 5px;
  color: rgb(129, 129, 129);
  font-size: 1vw;
  display: flex;
  font-style: italic;
  flex-wrap: wrap;
  margin-top: 1rem;
  justify-content: center;
}

.interval_wrap {
  position: relative;
}

.interval_box {
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: center;
}

.interval,
.interval_active {
  width: 100%;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.1rem;
  border-radius: 10px;
  margin-top: 0.3rem;
  margin-left: 0.3rem;
  width: 3rem;
  height: 1.5rem;
  text-align: center;

  &:hover {
    background-color: var(--neutral-color);
  }
}

.interval_active {
  color: var(--bs-btn-active-color);
  background-color: var(--neutral-color);
  border-color: var(--bs-btn-active-border-color);
}

.text_white {
  color: white
}

.intervalOff {
  color: #080808;
  background-color: white;
  border-color: #e4e4e4;
}

.intervalOn {
  color: #080808;
  background-color: #b2b2b2;
  border-color: #e4e4e4;
}


.diaryoneWeek {
  display: flex;
  margin-bottom: 3px;
}

.diaryoneWeekLesson {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;

  border: 1px solid grey;
  width: 5rem;
}

.diaryoneWeekNote {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid grey;
  width: 15rem;

}



.simpleBtn,
.simpleDiv,
.simpleCheck {
  border-radius: 5px;
  padding: 5px 15px;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5),
    7px 7px 20px 0px rgba(0, 0, 0, .1),
    4px 4px 5px 0px rgba(0, 0, 0, .1);
  outline: none;
  font-size: 14px;
  border: none;
  color: #fff;
  background: linear-gradient(0deg, var(--light-color) 0%, var(--light-color) 100%);
  text-decoration: none;
  text-wrap: nowrap;
  height: fit-content;
}

.simpleBtn {
  &:disabled {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    background: var(--fill-color);
  }

  &:hover {
    box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, .5),
      -4px -4px 6px 0 rgba(116, 125, 136, .5),
      inset -4px -4px 6px 0 rgba(255, 255, 255, .2),
      inset 4px 4px 6px 0 rgba(0, 0, 0, .4);
  }
}

.schlist-btn {
  border: none;
  font-size: 5rem;
  padding: 5px;
  margin: 5px;
  line-height: 1;
  color: var(--neutral-color);

  &:hover {
    color: #4d5b58;
    background-color: white;
  }
}