
  .avatarProfile{
   width: 100%;
  }
  .mainImg{
        background-image: url(../../img/mainBg.jpg);
        background-size: 100%;
        min-height: 100vh;background-attachment:scroll;

   }.glass{
      /* background-color:white; */
      border: 1px solid rgba(43, 43, 43, 0.568);
      width:25rem;  
      height: 15rem;
      margin: auto;
      margin-top: 5rem;
      border-radius: 35px ;
      backdrop-filter: blur(3px);
      box-sizing: border-box;
      box-shadow: 15px 16px 16px -10px rgba(66, 68, 90, 1);
      /* background-attachment: fixed; */
    }



@media screen and (min-width: 600px) {
  .avatarDiv{
    width: 20%;
    max-width: 200px;
text-align: center;   margin-top: 6rem;
  }

}
@media screen and (max-width: 600px) {
  .avatarDiv{
    width: 30%;
    max-width: 200px;
    text-align: center;
    margin-top: 6rem;
  }

}
.imgPreview{
  /* margin-right: 2rem; */
  /* margin-bottom: 2rem; */
  /* width: 22%; */
  /* max-width: 100px; */
  /* height:  22%; */
  /* float: left; */

  box-shadow: 3px;
  /* background-color: rgb(255, 255, 255); */
}
.imgGallary{
  width: 30%;
  height:  30%;
  /* margin-left: 0.5rem;
  margin-right: 0.5rem;               */
}
.btn{
  display:flex;
  justify-content: center;
  /* flex-direction: column; */
  /* width: 30%; */
}
.previewBlock{
  display:flex;
  flex-direction: column;
  width: 30%;  
  height:  30%;
  box-shadow: 3px;
  /* border: 2px solid  #888888; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; */
}