.num {
    font-size: 0.9rem;
}

.cardHeader {
    display: flex;
    justify-content: space-between;
    font-size: "1wv"
}




.scheduleContainer {
    box-sizing: border-box;
    width: 15rem;
    min-height: 13rem;
    margin-left: 2rem;
    margin-top: 1rem;
    border-radius: 15px 15px 45px 45px;
    background-color: var(--my-bg-colorlight);

    -webkit-box-shadow: 15px 12px 16px -10px rgba(66, 68, 90, 1);
    -moz-box-shadow: 15px 12px 16px -10px rgba(66, 68, 90, 1);
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    transition: all 0.2s ease-in-out;
}

.scheduleContainer:hover {
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    transform: translateY(-5px);
}

.scheduleTitle {
    margin-top: -0.1rem;
    display: flex;
    justify-content: center;
    font-weight: 700;
    padding: 10px;
    text-align: center;
    width: 100.5%;
    height: 25%;
    border: none;
    background-color: var(--my-bg-colordark);
    border-radius: 8px 8px 0px 0px;
    color: white;
    text-transform: uppercase;


}

.clipLeft {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0.5rem;
    margin-top: -0.9rem;
    height: 3rem;
    width: 7%;
}

.clipRight {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 0.5rem;
    margin-top: -0.9rem;
    width: 7%;
}

.clipTop {
    background-color: var(--my-border-color);
    width: 1.1rem;
    height: 1rem;

    margin-top: -0.8rem;
    border-radius: 50% 50% 0 0
}

.clip {
    background-color: var(--my-border-color);
    width: 80%;
    height: 2rem;
    margin-top: -0.8rem;
    border-radius: 0px 0px 8px 8px;
}

.scheduleBody {
    padding-top: 20px;
    text-align: center;
    display: flex;
    justify-content: space-around;
    align-content: center;
}