.page {
  
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    font-family: "Open Sans", sans-serif;
    color: #333333;
    /* background-image: url(../../img/mainBg.jpg); */
    background-image: url(../../img/loginPage.jpg);
    background-size: 100%;
    min-height: 100vh;background-attachment:fixed;
  }
  
  .box-form {
    margin: 0 auto;
    width: 60%;
    height: 30rem;
    min-width: 50%;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex: 1 1 100%;
    align-items: stretch;
    justify-content: space-between;
    box-shadow: 0 0 20px 6px #6f460985;
  }
  
  @media (max-width: 980px) {
    .box-form {
      flex-flow: wrap;
      text-align: center;
      align-content: center;
      align-items: center;
    }
  }
  /* .box-form .divlogin {
    height: auto;
  } */
  .box-form .left {
    color: #FFFFFF;    height: auto;
    /* background-size: cover;
    background-repeat: no-repeat;
      background-image: url(../../img/mainBg.jpg); */
    
    overflow: hidden;
  }
  .box-form .left .overlay {
    padding: 30px;
    width: 100%;
    height: 100%;

    background: #959599ad;
    overflow: hidden;
    box-sizing: border-box;
  }
  .box-form .left .overlay h1 {
    font-size: 10rem;
    line-height: 1;
    font-weight: 900;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .box-form .left .overlay span p {
    margin-top: 30px;
    font-weight: 900;
  }
  .box-form .left .overlay span a {
    /* background: #3b5998; */
    color: #FFFFFF;
    margin-top: 10px;
    padding: 14px 50px;
    border-radius: 100px;
    display: inline-block;
    box-shadow: 0 3px 6px 1px #042d4657;
  }
  .box-form .left .overlay span a:last-child {
    /* background: #1dcaff; */
    margin-left: 30px;
  }
  .box-form .right {
    width:35rem;
    padding: 20px;
    overflow: hidden; 
     background: #FFFFFF;
  }
  @media (max-width: 980px) {
    .box-form .right {
      width: 100%;
    }
  }
  .box-form .right h5 {
    font-size: 7rem;
    margin-top: 3rem;
    margin-bottom: 5rem;
    line-height: 0;
    
  }
  /* @media screen and (min-width: 600px) and (min-height: 600px) */
  @media (max-width: 900px)  {
    .box-form{
      width:90%;
      height: fit-content;
    }
    .page {
         background-size: cover;
         }
         .box-form {
          flex-flow: wrap;
          text-align: center;
          align-content: center;
          align-items: center;
        }
        .box-form .right h5 {
          font-size: 2rem;
          line-height: 0;
          margin: 0;
          /* height: 15rem; */
          
        }
        .box-form .left .overlay h1 {
          font-size: 4rem;}
  }

  .box-form .right p {
    font-size: 14px;
    color: #B0B3B9;
  }
  .box-form .right .inputs {
    overflow: hidden;
  }
  .box-form .right .login_input {
    width: 100%;
    padding: 10px;
    margin-top: 25px;
    font-size: 16px;
    border: none;
    outline: none;
    border-bottom: 2px solid #B0B3B9;
  }
  .box-form .right .remember-me--forget-password {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  /* .box-form .right .remember-me--forget-password input {
    margin: 0;
    margin-right: 7px;
    width: auto;
  } */
  .btns {
    /* float:right; */
    /* color: #fff; */
    /* font-size: 16px; */
    /* padding: 12px 35px; */
    border-radius:   15px  35px   35px   15px   ;
    width:100%;
    /* display: block; */
    /* border: 0; */
    /* outline: 0; */
    /* box-shadow: 0px 4px 20px 0px #49c628a6;
    background-image: linear-gradient(135deg, #70F570 10%, #49C628 100%); */
    /* box-shadow: 0px 4px 20px 0px #C5B37E; */
    /* background-image: linear-gradient(135deg, rgb(240, 244, 149) 10%, #C5B37E 100%); */
  }
  

  
  /* label::before {
    content: ' \f00c';
    position: absolute;
    font-family: FontAwesome;
    background: transparent;
    border: 3px solid #70F570;
    border-radius: 4px;
    color: transparent;
    left: -30px;
    transition: all 0.2s linear;
  } */
/*   
  label:hover::before {
    font-family: FontAwesome;
    content: ' \f00c';
    color: #fff;
    cursor: pointer;
    background: #70F570;
  }
   */
  /* label:hover::before .text-checkbox {
    background: #70F570;
  } */
  
  /* label span.text-checkbox {
    display: inline-block;
    height: auto;
    position: relative;
    cursor: pointer;
    transition: all 0.2s linear;
  }
  
  label input[type="checkbox"] {
    display: none;
  } */