.sideBarMain{
    height: inherit;
    border:1px solid rgb(120, 200, 0);
    display: flex;
    position: relative;
    top:-15px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;

}
.sidebarBody{
    height: 100%;

    width:17rem;
    display:flex;
    border: 1px solid;background-color: white;

    height: 80vh;
     right: 0;
     overflow-x: auto;
     border-radius: 15px;
}