.oneLesson{
    background-color: #e6e6e6;
    font-size: 1.2rem;
}

.weekrow{
    display:flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    position: relative;
}.weekcolumn{

    display:flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    position: relative;
    flex-direction: column;
    width:95%;
}
.cardrow {
    width:20rem;
    margin: 1rem;
    margin-top: 0;
}.cardcolumn{
    width:100%;
    margin: 1rem;
    margin-top: 0;
}
.subjrow {
    box-sizing:border-box;
    width:8rem;
    border-right:1px  solid #DBDBDB;
    border-top-right-radius: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
}.subjcolumn{
    box-sizing:border-box;
    width:14rem;
    border-right:1px  solid #DBDBDB;
    border-top-right-radius: 5px;
    text-overflow: ellipsis;
}


.note{
    padding-left: 8rem;
    width: 100%;
    height: "1.5rem";
    text-overflow: ellipsis;
    border-left:1px  solid #DBDBDB;  
    border-top-left-radius: 5px;
}


