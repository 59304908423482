.monthrow {
    display: flex;
    justify-content: flex-start;
    width: fit-content
}

.monthcolumn {
    display: flex;
    margin: auto;
    justify-content: flex-start;
}

.calendarrow {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 90%;
}

.calendarcolumn {
    display: flex;

}

.weekrow {
    margin-top: 1.5rem;
    width: 100%;
    display: flex;
    justify-content: flex-start;


}

.weekcolumn {
    margin-top: 1.5rem;
    margin-left: 1rem;
    width: 100%;
}


.subjectrow {
    box-sizing: border-box;
    width: 8rem;
    min-width: 8rem;
    overflow: hidden;
    border-right: 1px solid #DBDBDB;
    border-top-right-radius: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.lessonboxcolumn {
    display: flex;
    width: 100%;
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 14rem;
    border-bottom: 0.3px solid #F5F5F5;
    position: relative;
    height: 1.5rem;
    margin-bottom: 1px;
}

.lessonboxrow {
    background-color: #e6e6e6;
    font-size: 1.2rem;
    display: flex;
    width: 100%;
    border-bottom: 0.3px solid #F5F5F5;
    position: relative;
}

.dayheadercolumn {
    padding: 8px 16px;
    border-radius: 10px 10px 0 0 !important;
    width: 14rem;

}

.dayheaderrow {
    border-radius: 10px 10px 0 0 !important;
    width: 20rem;
    padding: 10px;
}

.dayWrap {
    border: 1px solid #d2d2d2;
    border-radius: 10px;
    margin: 1px;
    padding-bottom: 10px;
    position: relative;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

    sup {
        top: 0;
    }
}

.cardrow {
    width: 20rem;
    margin-left: 1rem;
}

.cardcolumn {

    width: 14rem;
}

.todayrow {
    color: white;
    border: 1px solid var(--my-border-color);
    background-color: var(--my-bg-colordark);
    ;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.todaycolumn {
    color: var(--my-bg-colordark);
    color: white;
    border: 1px solid var(--my-border-color);
    background-color: var(--my-bg-colordark);
    border-radius: 5px;

}

.numberstyle {
    color: #787878;
    font-size: 14px;
    background-color: var(--neutral-color);
}