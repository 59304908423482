.oneLesson{
    display: flex;
    background-color: #e6e6e6;
    font-size: 1.2rem;
}

.note{
    width: 85%;
    text-overflow: ellipsis;
    border-left:1px  solid #DBDBDB;  
    border-top-left-radius: 5px;
}
.subjrow {
    box-sizing:border-box;
    width: 15%;
    border-right:1px  solid #DBDBDB;
    border-top-right-radius: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
}.subjcolumn{
    box-sizing:border-box;
    width:14rem;
    border-right:1px  solid #DBDBDB;
    border-top-right-radius: 5px;
    text-overflow: ellipsis;
}
